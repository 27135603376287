console.log('Версия сайта для слабовидящих');

function getCookie() {
  return document.cookie.split("; ").reduce((acc, item) => {
    const [name, value] = item.split("=");
    acc[name] = value;
    return acc;
  }, {});
}


const initBVI = () => {
  const loadBVI = (autoInit) => {
    const scriptEl = document.createElement("script");
    scriptEl.setAttribute("type", "text/javascript");
    scriptEl.setAttribute("src", "/assets/js/bvi.js");
    scriptEl.setAttribute("async", false);
    scriptEl.addEventListener("load", () => {
      new isvek.Bvi({ target: ".bvi-open", fixed: false });

      if (autoInit) {
        document
          .querySelector(".bvi-open")
          ?.click();
      }
    });

    document.body.appendChild(scriptEl);
  };

  const cookie = getCookie();

  if (cookie.bvi_panelActive) {
    loadBVI();
    return;
  }

  document.addEventListener("click", (e) => {
    const el = e.target?.closest(".bvi-open");

    if (!el) {
      return;
    }

    if (typeof window?.isvek === "undefined") {
      loadBVI(true);
    }
  });
};

initBVI();
